import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

const InternalBI = () => {
  return (
    <Tabs className="w-full h-full" defaultValue="individual-reports">
      <TabsList className="grid w-full grid-cols-3">
        <TabsTrigger value="individual-reports">Individual Reports</TabsTrigger>
        <TabsTrigger value="genai-summaries-feedback">
          GenAI Summaries Feedback
        </TabsTrigger>
        <TabsTrigger value="usage-metrics">Usage Metrics</TabsTrigger>
      </TabsList>
      <TabsContent className="h-full" value="individual-reports">
        <iframe
          allowFullScreen={true}
          height={'100%'}
          src="https://app.powerbi.com/reportEmbed?reportId=60b17487-a08d-4a3f-8ca7-102ff857eae8&autoAuth=true&ctid=33d4f3e5-3df2-421e-b92e-a63cfa680a88"
          title="epilot Internal BI"
          width={'100%'}
        />
      </TabsContent>
      <TabsContent className="h-full" value="genai-summaries-feedback">
        <iframe
          allowFullScreen={true}
          height={'100%'}
          src="https://app.powerbi.com/reportEmbed?reportId=67644a7e-6634-4c07-88ca-c6a9464fe412&autoAuth=true&ctid=33d4f3e5-3df2-421e-b92e-a63cfa680a88"
          title="GenAI Summaries BI"
          width={'100%'}
        />
      </TabsContent>
      <TabsContent className="h-full" value="usage-metrics">
        <iframe
          allowFullScreen={true}
          height={'100%'}
          src="https://app.powerbi.com/reportEmbed?reportId=42db6be9-1808-4283-bebd-f141a2dfb837&autoAuth=true&ctid=33d4f3e5-3df2-421e-b92e-a63cfa680a88"
          title="Usage Metrics BI"
          width={'100%'}
        />
      </TabsContent>
    </Tabs>
  )
}

export default InternalBI
