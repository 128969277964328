type UsageMetricsProps = {
  orgId: string
}

const UsageMetrics = (props: UsageMetricsProps) => {
  const { orgId } = props

  return (
    <div style={{ height: 'calc(100vh - 5rem)' }}>
      <iframe
        allowFullScreen={true}
        height={'100%'}
        src={`https://app.powerbi.com/reportEmbed?reportId=42db6be9-1808-4283-bebd-f141a2dfb837&autoAuth=true&ctid=33d4f3e5-3df2-421e-b92e-a63cfa680a88&filter=usage_metrics/org_id eq '${orgId}'`}
        title="usage_metrics"
        width={'100%'}
      />
    </div>
  )
}

export default UsageMetrics
